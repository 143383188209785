import revive_payload_client_tNz2yXc6KQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+reset@0.61_m2xsmtdkzpe2saapsxyay2dfii/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_j2O2XBfq6E from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+reset@0.61_m2xsmtdkzpe2saapsxyay2dfii/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XEdO8FRmTb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+reset@0.61_m2xsmtdkzpe2saapsxyay2dfii/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_EVoNLfAxAL from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.11_@unocss+webpack@0.58.9_rollup@3.29.4_webpack@5.91.0_@swc+core@1.7.0_@_om65vjestdgdswxljw5lzngeuy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import index_trBkPJFIXi from "/opt/build/repo/domains/core/plugin/requests/index.ts";
import index_client_Bxorl0Qv15 from "/opt/build/repo/domains/commerce/plugin/featureFlags/index.client.ts";
import payload_client_eFN2q7mQI8 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+reset@0.61_m2xsmtdkzpe2saapsxyay2dfii/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_U44Yx4cPO2 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+reset@0.61_m2xsmtdkzpe2saapsxyay2dfii/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_CxIitMtqoB from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@3.29.4_typescript@5.4.5_vue@3.4.30_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_n4o99Rm8Zg from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+reset@0.61_m2xsmtdkzpe2saapsxyay2dfii/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_QIZEN83PpI from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@3.29.4_vite@5.3.3_@types+node@22.10.1_terser@5.31.2__workbox-buil_mlnmbupnbu4xdiidnvneicby6a/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_oaw2U0tu6i from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@3.29.4_vite@5.3.3_@types+node@22.10.1_terser@5.31.2__workbox-buil_mlnmbupnbu4xdiidnvneicby6a/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import unocss_MzCDxu9LMj from "/opt/build/repo/.nuxt/unocss.mjs";
import plugin_mEDQWL9JMr from "/opt/build/repo/domains/core/modules/motion/runtime/plugin.ts";
import chunk_reload_client_VsvUWsFaHe from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+reset@0.61_m2xsmtdkzpe2saapsxyay2dfii/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_HmyHYoiBjT from "/opt/build/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@3.29.4_typescript@5.4.5_vue@_k5mto7kdkh7m2zji2djd6vyc7m/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import index_FYqhnx6PPB from "/opt/build/repo/domains/core/plugin/os/index.ts";
import index_T1TY9w77gQ from "/opt/build/repo/domains/core/plugin/viewport/index.ts";
import index_OiaMpUpAu4 from "/opt/build/repo/domains/core/plugin/i18n/index.ts";
import index_client_Htai7mnyDF from "/opt/build/repo/domains/core/plugin/access/index.client.ts";
import index_GlsltH37zp from "/opt/build/repo/domains/core/plugin/api/index.ts";
import index_TeZlGNUwia from "/opt/build/repo/domains/core/plugin/errors/index.ts";
import index_UIMBcTi8o3 from "/opt/build/repo/domains/core/plugin/dynatrace/index.ts";
import index_UNtQ8njf8Z from "/opt/build/repo/domains/core/plugin/schemaOrg/index.ts";
import index_client_5KXeDalwbx from "/opt/build/repo/domains/core/plugin/favorites/index.client.ts";
import index_saAkDG77pG from "/opt/build/repo/domains/core/plugin/style/index.ts";
import index_client_cfauvI9WEI from "/opt/build/repo/domains/core/plugin/deviceFingerPrint/index.client.ts";
import index_client_32Zk9oqExA from "/opt/build/repo/domains/core/plugin/forter/index.client.ts";
import index_client_t1qg5f5uLO from "/opt/build/repo/domains/core/plugin/piniaSharedState/index.client.ts";
import index_client_3xYF97PKkO from "/opt/build/repo/domains/core/plugin/employeeSession/index.client.ts";
import index_rM9ugsIZbR from "/opt/build/repo/domains/core/plugin/performance/index.ts";
import index_client_Oq6BKvM60N from "/opt/build/repo/domains/commerce/plugin/human/index.client.ts";
import index_client_0kDclIS46F from "/opt/build/repo/domains/commerce/plugin/oneTrust/index.client.ts";
import index_client_IFXxRtJfDl from "/opt/build/repo/domains/commerce/plugin/gtm/index.client.ts";
import index_boV37WgoMr from "/opt/build/repo/domains/commerce/plugin/p13n/index.ts";
import index_client_BQ7SgnGFnA from "/opt/build/repo/domains/commerce/plugin/p13n/index.client.ts";
import index_client_Y0okPA3dRG from "/opt/build/repo/domains/commerce/plugin/locationConfirmation/index.client.ts";
import index_client_Ss3ffGYg3N from "/opt/build/repo/domains/content/plugin/crl8/index.client.ts";
import index_eDLYL5pWwZ from "/opt/build/repo/domains/content/plugin/preview/index.ts";
import index_wdGryub34Z from "/opt/build/repo/domains/brands/plugin/seo/index.ts";
import index_client_lduHgOIvee from "/opt/build/repo/brands/timberland/plugin/chat/index.client.ts";
export default [
  revive_payload_client_tNz2yXc6KQ,
  unhead_j2O2XBfq6E,
  router_XEdO8FRmTb,
  _0_siteConfig_EVoNLfAxAL,
  index_trBkPJFIXi,
  index_client_Bxorl0Qv15,
  payload_client_eFN2q7mQI8,
  check_outdated_build_client_U44Yx4cPO2,
  plugin_vue3_CxIitMtqoB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_n4o99Rm8Zg,
  pwa_icons_QIZEN83PpI,
  pwa_client_oaw2U0tu6i,
  unocss_MzCDxu9LMj,
  plugin_mEDQWL9JMr,
  chunk_reload_client_VsvUWsFaHe,
  plugin_HmyHYoiBjT,
  index_FYqhnx6PPB,
  index_T1TY9w77gQ,
  index_OiaMpUpAu4,
  index_client_Htai7mnyDF,
  index_GlsltH37zp,
  index_TeZlGNUwia,
  index_UIMBcTi8o3,
  index_UNtQ8njf8Z,
  index_client_5KXeDalwbx,
  index_saAkDG77pG,
  index_client_cfauvI9WEI,
  index_client_32Zk9oqExA,
  index_client_t1qg5f5uLO,
  index_client_3xYF97PKkO,
  index_rM9ugsIZbR,
  index_client_Oq6BKvM60N,
  index_client_0kDclIS46F,
  index_client_IFXxRtJfDl,
  index_boV37WgoMr,
  index_client_BQ7SgnGFnA,
  index_client_Y0okPA3dRG,
  index_client_Ss3ffGYg3N,
  index_eDLYL5pWwZ,
  index_wdGryub34Z,
  index_client_lduHgOIvee
]